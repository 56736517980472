import * as actionTypes from "../constants";

export function getContactsWithKeyword(data) {
  return { data, type: actionTypes.GET_CONTACTS_WITH_KEYWORD };
}

export function getContactsSuccess(data) {
  return { data, type: actionTypes.GET_CONTACTS_SUCCESS };
}

export function addContact(data) {
  return { data, type: actionTypes.ADD_CONTACT };
}

export function addContactSuccess(data) {
  return { data, type: actionTypes.ADD_CONTACT_SUCCESS };
}

export function updateContact(data) {
  return { data, type: actionTypes.UPDATE_CONTACT };
}

export function updateContactSuccess(data) {
  return { data, type: actionTypes.UPDATE_CONTACT_SUCCESS };
}

export function removeContact(data) {
  return { data, type: actionTypes.REMOVE_CONTACT };
}

export function removeContactSuccess(data) {
  return { data, type: actionTypes.REMOVE_CONTACT_SUCCESS };
}

export function sendMailActiveAccount(data) {
  return { data, type: actionTypes.SEND_MAIL_ACTIVE_ACCOUNT };
}

export function sendMailSuccess(data) {
  return { data, type: actionTypes.SEND_MAIL_SUCCESS };
}
