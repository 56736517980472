import * as actionTypes from "../constants";

const initialState = {
  status: {},
  image: {},
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_IMAGE_SUCCESS:
      return {
        ...state,
        image: action.data,
      };
    default:
      return state;
  }
};

export default fileReducer;
