import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";

import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./index.css";
import "../../node_modules/font-awesome/scss/font-awesome.scss";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import { isAuthenticated, handleLogout } from "../stores/storages";
import AutoLogout from "./components/AutoLogout";

const LoginPage = React.lazy(() => import("../Demo/Authentication/Login"));
const ResetPasswordPage = React.lazy(() => import("../Demo/ResetPassword"));
const ForgotPasswordPage = React.lazy(() => import("../Demo/ResetPassword/FotgotPassword"));

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const requireNone = (Component) => (props) => {
  return !isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />;
};

const resetPasswordAccess = (Component) => (props) => {
  handleLogout();
  return <Component {...props} />
}
class App extends Component {

  render() {
    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login"
                render={requireNone(LoginPage)}
              />
              <Route
                exact
                path="/:token/resetpassword"
                name="ResetPassword"
                render={resetPasswordAccess(ResetPasswordPage)}
              />
              <Route
                exact
                path="/forgotpassword"
                name="ForgotPassword"
                render={requireNone(ForgotPasswordPage)}
              />
              <Route path="/" component={AdminLayout} />
            </Switch>
          </Suspense>
        </ScrollToTop>
        <Alert
          stack={{ limit: 3 }}
          timeout={3000}
          position="top-right"
          effect="slide"
          offset={65}
        />
        <AutoLogout/>
      </Aux>
    );
  }
}

export default App;
