import * as actionTypes from "../constants";

export function getLoginHistory(data) {
  return { data, type: actionTypes.GET_LOGIN_HISTORY };
}

export function getLoginHistorySuccess(data) {
  return { data, type: actionTypes.GET_LOGIN_HISTORYS_SUCCESS };
}

export function exportLoginHistory(data) {
  return { data, type: actionTypes.EXPORT_LOGIN_HISTORY };
}

export function exportLoginHistorySuccess(data) {
  return { data, type: actionTypes.EXPORT_LOGIN_HISTORYS_SUCCESS };
}

