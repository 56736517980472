import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const getConfigsWithKeys = (keys) => {
  return httpService.get(`${urlConfig.urlConfigurations}?keys=${keys}`);
};

export const updateConfigs = (array) => {
  return httpService.put(`${urlConfig.urlConfigurations}?keys`, array);
};
