import * as actionTypes from "../constants";

export const throwError = (data) => ({
  data,
  type: actionTypes.THROW_ERROR,
});

export function handleError(data) {
  return { data, type: actionTypes.HANDLE_ERROR };
}
