import * as actionTypes from '../constants';

const initialState = {
  token: {},
  roles: {},
  error: {},
  logout: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_DO_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.data,
      };
    case actionTypes.AUTH_DO_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        error: action.data,
      };
    case actionTypes.AUTH_DO_GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.data,
      };
    case actionTypes.AUTH_DO_LOG_OUT_SUCCESS:
      return {
        token: {},
          roles: {},
          error: {},
          logout: action.data,
      };
    default:
      return state;
  }
};

export default authReducer;