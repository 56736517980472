import moment from "moment";

export default class TimeUtils {
  static transformListFromEpochMilisToDateTimeString = (objects) => {
    let convertedObjects = objects.map(
      this.transformObjectFromEpochMilisToDateTimeString
    );
    return convertedObjects;
  };

  static transformObjectFromEpochMilisToDateTimeString = (object) => {
    object.createdDate = this.convertToDateTimeString(object.createdAt);
    object.updatedDate = this.convertToDateTimeString(object.updatedAt);
    return object;
  };

  static convertToDateTimeString(epochTime) {
    let dateString = moment
      .unix(epochTime / 1000)
      .format("YYYY/MM/DD HH:mm:ss");
    return dateString;
  }
}
