import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const getContactsWithKeyword = (keyword) => {
  return httpService.get(`${urlConfig.urlContacts}?keyword=${keyword}`);
};

export const addContact = (contact) => {
  return httpService.post(`${urlConfig.urlContacts}`, contact);
};

export const updateContact = (contact) => {
  const updateContact = {
    fullname: contact.fullname,
    username: contact.username,
    department: contact.department,
    mailTypes: contact.mailTypes,
    enabled: contact.enabled,
    roleName: contact.roleName,
  };
  return httpService.put(
    `${urlConfig.urlContacts}/${contact.id}`,
    updateContact
  );
};

export const removeContact = (id) => {
  return httpService.delete(`${urlConfig.urlContacts}/${id}`);
};

export const sendMailActiveAccount = (contact) => {
  return httpService.post(`${urlConfig.urlSendMailActiveAccount}`, { "email": contact });
}