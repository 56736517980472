import * as actionTypes from "../constants";

export function searchReportList(data) {
  return { data, type: actionTypes.REPORT_LIST_SEARCH_REPORT };
}

export function searchReportListSuccess(data) {
  return { data, type: actionTypes.REPORT_LIST_SEARCH_REPORT_SUCCESS };
}

export function getReportDetail(data) {
  return { data, type: actionTypes.GET_REPORT_DETAIL };
}

export function getReportDetailSuccess(data) {
  return { data, type: actionTypes.GET_REPORT_DETAIL_SUCCESS };
}

export function deleteReportManually(data) {
  return { data, type: actionTypes.DELETE_REPORT_MANUALLY };
}

export function deleteReportManuallySuccess(data) {
  return { data, type: actionTypes.DELETE_REPORT_MANUALLY_SUCCESS };
}
