
import CryptoJS from "crypto-js"

const encryptKey = process.env.REACT_APP_ENCRYPT_KEY;

export default class EncryptUtils {

  static encrypt(plainText) {
    if (plainText) {
      return CryptoJS.AES.encrypt(plainText, encryptKey).toString();
    }

    return null;
  }

  static decrypt(encryptedString) {
    if (encryptedString) {
      let bytes = CryptoJS.AES.decrypt(encryptedString, encryptKey);

      let plainText = bytes.toString(CryptoJS.enc.Utf8);

      return plainText;
    }
    return null;

  }
}