import * as actionTypes from "../constants";

export function getConfigsWithKeys(keys) {
  return { keys, type: actionTypes.GET_CONFIGS_WITH_KEYS };
}

export function getConfigsWithKeysSuccess(data) {
  return { data, type: actionTypes.GET_CONFIGS_WITH_KEYS_SUCCESS };
}

export function updateConfigs(array) {
  return { array, type: actionTypes.UPDATE_CONFIGS };
}

export function updateConfigsSuccess(data) {
  return { data, type: actionTypes.UPDATE_CONFIGS_SUCCESS };
}
