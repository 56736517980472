import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const searchLoginHistoryList = (dataSearch) => {
  return httpService.post(urlConfig.urlLoginHistory, dataSearch);
};

export const exportLoginHistoryList = (dataSearch) => {
  return httpService.post(urlConfig.urlExportLoginHistory, dataSearch);
};
