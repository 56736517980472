import { combineReducers } from "redux";

import errorReducer from "./errorReducer";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import contactReducer from "./contactReducer";
import deviceReducer from "./deviceReducer";
import dashboardReducer from "./dashboardReducer";
import configurationReducer from "./configurationReducer";
import reportReducer from "./reportReducer";
import fileReducer from "./fileReducer";
import userReducer from "./userReducer";
import roleReducer from "./roleReducer";
import loginHistoryReducer from "./loginHistoryReducer";

export default combineReducers({
  error: errorReducer,
  app: appReducer,
  auth: authReducer,
  contact: contactReducer,
  device: deviceReducer,
  dashboardStore: dashboardReducer,
  configuration: configurationReducer,
  report: reportReducer,
  file: fileReducer,
  user: userReducer,
  role: roleReducer,
  loginHistory: loginHistoryReducer,
});
