import HttpService from './httpService'

const CreateHttpService = () => {
  let defaultConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': null,
    }
  }
  return new HttpService(defaultConfig)
}

export default CreateHttpService;
