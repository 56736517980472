import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const getDevices = () => {
  return httpService.get(urlConfig.urlDevices);
};

export const updateDevice = (data) => {
  return httpService.put(urlConfig.urlDevices, data);
};

export const removeDevice = (id) => {
  return httpService.delete(`${urlConfig.urlDevices}/${id}`);
};
