import React from 'react';

import { handleLogout } from "../../../stores/storages";
import { comfirmPopup } from "../ConfirmPopup";
import * as actions from "../../../stores/actions";
import { connect } from 'react-redux';

class AutoLogout extends React.Component {

  componentDidMount() {
    this.intervalId = this.handleInterval();
    window.addEventListener('mousedown', this.timer, false);
  }

  timer = (e) => {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    this.intervalId = this.handleInterval();
  }

  handleInterval = () => {
    return setInterval(() => {
      if (window.location.pathname !== "/login") {
        this.props.doAutoLogout();
        handleLogout();
        comfirmPopup({
          message: "セッションタイムアウトになりました。再度ログインしてください。",
          buttonPositve: "Yes",
          onClickButonPositive: function () {
            window.location.pathname = "/login";
          },
        });
      }
      clearInterval(this.intervalId);
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    console.log('app componentWillUnmount')
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
  
  render() {
      return null;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    doAutoLogout: () => dispatch(actions.doAutoLogout()),
  }
};

export default connect(null, mapDispatchToProps)(AutoLogout);