import {
  takeEvery,
  put
} from "redux-saga/effects";
import * as HttpStatus from "http-status-codes";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  comfirmPopup
} from "../../App/components/ConfirmPopup";
import {
  handleLogout
} from "../../stores/storages";

function* catchError(action) {
  const {
    data
  } = action;

  yield put(actions.cancelLoading());

  const response = data.response;

  if (!response || response.status === HttpStatus.NOT_FOUND) {
    yield put(actions.doLogoutSuccess());
    handleLogout();
    comfirmPopup({
      message: "クラウド側に接続できません。再度ログインしてください。",
      buttonPositve: "Yes",
      onClickButonPositive: function () {
        if (window.location.pathname !== "/login") {
          window.location.pathname = "/login";
        }
      },
    });
    return;
  }

  if (response.status === HttpStatus.UNAUTHORIZED) {
    yield put(actions.doLogoutSuccess());
    handleLogout();
    comfirmPopup({
      message: "セッションタイムアウトになりました。再度ログインしてください。",
      buttonPositve: "Yes",
      onClickButonPositive: function () {
        handleLogout();
        if (window.location.pathname !== "/login") {
          window.location.pathname = "/login";
        }
      },
    });
    return;
  }

  if (response.status === HttpStatus.BAD_REQUEST) {
    if (
      response.data &&
      (response.data.error === "invalid_grant" ||
        response.data.error === "invalid_token")
    ) {
      yield put(actions.doLogoutSuccess());
      handleLogout();
      comfirmPopup({
        message: "セッションタイムアウトになりました。再度ログインしてください。",
        buttonPositve: "Yes",
        onClickButonPositive: function () {
          handleLogout();
          if (window.location.pathname !== "/login") {
            window.location.pathname = "/login";
          }
        },
      });
      return;
    }

    const meta = response.data.meta ?
      response.data.meta : {
        message: "Input is invalid",
      };
    yield put(actions.handleError(meta));
    return;
  }

  yield put(actions.handleError(response.data.meta));
  return;
}

export default function* watchError() {
  yield takeEvery(actionTypes.THROW_ERROR, catchError);
}