import {
  takeEvery,
  call,
  put
} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  searchLoginHistoryList,
  exportLoginHistoryList
} from "../../services/loginHistory/loginHistoryService";

function* doGetLoginHistoryList(data) {
  try {
    let dataSearch = data.data;
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Get login history ..."
      })
    );
    let response = yield call(searchLoginHistoryList, dataSearch);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getLoginHistorySuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doExportLoginHistoryList(data) {
  try {
    let dataSearch = data.data;
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Get login history ..."
      })
    );
    let response = yield call(exportLoginHistoryList, dataSearch);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(
      actions.exportLoginHistorySuccess({
        data: response.data,
        exportFilename: `ログイン履歴_${Date.now()}.csv`,
      })
    );
  } catch (error) {
    yield put(actions.throwError(error));
  }
}


export default function* watchDevice() {
  yield takeEvery(actionTypes.GET_LOGIN_HISTORY, doGetLoginHistoryList);
  yield takeEvery(actionTypes.EXPORT_LOGIN_HISTORY, doExportLoginHistoryList);
}