import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  getContactsWithKeyword,
  addContact,
  updateContact,
  removeContact,
  sendMailActiveAccount,
} from "../../services/contact/contactService";
import TimeUtils from "../../Util/TimeUtils";

function* handleGetContacts(action) {
  try {
    const { data } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Get contacts ..." })
    );
    let response = yield call(getContactsWithKeyword, data.keyword);

    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    const convertedData = TimeUtils.transformListFromEpochMilisToDateTimeString(
      response.data.data
    );

    yield put(actions.getContactsSuccess(convertedData));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* handleAddContact(action) {
  try {
    const { data } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Add contact..." })
    );
    let response = yield call(addContact, data);

    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    const convertedData = TimeUtils.transformObjectFromEpochMilisToDateTimeString(
      response.data.data
    );
    yield put(actions.addContactSuccess(convertedData));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* handleUpdateContact(action) {
  try {
    const { data } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Update contact..." })
    );
    let response = yield call(updateContact, data);

    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    const convertedData = TimeUtils.transformObjectFromEpochMilisToDateTimeString(
      response.data.data
    );
    yield put(actions.updateContactSuccess(convertedData));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* handleRemoveContact(action) {
  try {
    const { data } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Remove contact..." })
    );
    let response = yield call(removeContact, data.id);

    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    yield put(actions.removeContactSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* handleSendMailActiveAccount(action) {
  try {
    const { data } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Send active account mail..." })
    );
    let response = yield call(sendMailActiveAccount, data.username);
    // cancel loading ...
    yield put(actions.cancelLoading());

    yield put(actions.sendMailSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchContact() {
  yield takeEvery(actionTypes.GET_CONTACTS_WITH_KEYWORD, handleGetContacts);
  yield takeEvery(actionTypes.ADD_CONTACT, handleAddContact);
  yield takeEvery(actionTypes.UPDATE_CONTACT, handleUpdateContact);
  yield takeEvery(actionTypes.REMOVE_CONTACT, handleRemoveContact);
  yield takeEvery(actionTypes.SEND_MAIL_ACTIVE_ACCOUNT, handleSendMailActiveAccount);
}
