import * as actionTypes from "../constants";

const initialState = {
  loginHistoryList: {},
  loginHistoryListMeta: {},
  csvData: null,
  exportFilename: ""
};

const loginHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOGIN_HISTORYS_SUCCESS:
      return {
        ...state,
        loginHistoryList: action.data.data,
        loginHistoryListMeta: action.data.meta,
      };

    case actionTypes.EXPORT_LOGIN_HISTORYS_SUCCESS:
      return {
        ...state,
        exportFilename: action.data.exportFilename,
        csvData: action.data.data
      };
    default:
      return state;
  }
};

export default loginHistoryReducer;
