import { all } from "redux-saga/effects";

import watchAuth from "./authSaga";
import watchError from "./errorSaga";
import watchContact from "./contactSaga";
import watchDevice from "./deviceSaga";
import watchDashboard from "./dashboardSaga";
import watchConfiguration from "./configurationSaga";
import watchReport from "./reportSaga";
import watchFile from "./fileSaga";
import watchUser from "./userSaga";
import watchRole from "./roleSaga";
import watchLoginHistory from "./loginHistorySaga";

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchError(),
    watchContact(),
    watchDevice(),
    watchDashboard(),
    watchConfiguration(),
    watchReport(),
    watchFile(),
    watchUser(),
    watchRole(),
    watchLoginHistory(),
  ]);
}
