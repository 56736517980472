import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import { getImage } from "../../services/file/fileService";

function* doGetImage(data) {
  try {
    let imgName = data.data;
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Get Image ..." })
    );

    let response = yield call(getImage, imgName);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getImageSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchDevice() {
  yield takeEvery(actionTypes.GET_IMAGE, doGetImage);
}
