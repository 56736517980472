
import Cookie from "universal-cookie";

export const cookie = new Cookie();

export default class CookieUtils {
  static getCookie(key) {
    if (cookie.get(key))
      return cookie.get(key);
    return null;
  }

  static setCookie(key, value, options) {
    return cookie.set(key, value, options);
  }

  static removeCookie(key) {
    return cookie.remove(key);
  }

}