import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  checkTestCaseStatus,
  searchReportDashboard
} from "../../services/dashboard/dashboardService";

function* doGetTestCaseStatus() {
  try {
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Get Test Case Status ..." })
    );
    let response = yield call(checkTestCaseStatus);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getTestCaseStatusSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doSearchReport(data) {
  try {
    const { timeDistance, startTime, endTime } = data.data;

    let dataSearch = {
      timeDistance: timeDistance,
      startTime: startTime,
      endTime: endTime
    }
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Dashboard Search Report ..." })
    );
    let response = yield call(searchReportDashboard, dataSearch);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.searchReportSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchDevice() {
  yield takeEvery(actionTypes.DASHBOARD_GET_TEST_CASE_STATUS, doGetTestCaseStatus);
  yield takeEvery(actionTypes.DASHBOARD_SEARCH_REPORT, doSearchReport);
}
