import {
  takeEvery,
  call,
  put
} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import * as HttpStatus from "http-status-codes";
import {
  getAccessToken,
  getRoles,
  logout
} from "../../services/auth/authService";

function* doLogin(action) {
  try {
    const {
      data
    } = action;
    // set loading ...
    yield put(actions.startLoading({
      isLoading: true,
      message: "Get access token..."
    }));
    // call get access token ...
    let response = yield call(getAccessToken, {
      username: data.username,
      password: data.password
    })
    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    yield put(actions.doLoginSuccess(response.data));
  } catch (error) {
    yield put(actions.authThrowError(error));
  }
}

function* doLogout() {
  try {
    // set loading ...
    yield put(actions.startLoading({
      isLoading: true,
      message: "Log out..."
    }));
    // call logout ...
    let response = yield call(logout)
    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    yield put(actions.doLogoutSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doAutoLogout() {
  try {
    // set loading ...
    yield put(actions.startLoading({
      isLoading: true,
      message: "Log out..."
    }));
    // call logout ...
    yield call(logout)
    // cancel loading ...
    yield put(actions.cancelLoading());
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doGetRoles() {
  try {
    // set loading ...
    yield put(actions.startLoading({
      isLoading: true,
      message: "Get roles..."
    }));
    // call get roles ...
    let response = yield call(getRoles)
    // cancel loading ...
    yield put(actions.cancelLoading());
    // set data ...
    yield put(actions.doGetRolesSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* authThrowError(action) {
  const {
    response
  } = action.data;

  if (!response || response.status === HttpStatus.NOT_FOUND) {
    yield put(actions.doLoginFailure({
      error: 'not-found'
    }));
  } else {
    yield put(actions.doLoginFailure(response.data));
  }
}

export default function* watchAuth() {
  yield takeEvery(actionTypes.AUTH_DO_ACCESS_TOKEN, doLogin);
  yield takeEvery(actionTypes.AUTH_DO_LOG_OUT, doLogout);
  yield takeEvery(actionTypes.AUTH_DO_GET_ROLES, doGetRoles);
  yield takeEvery(actionTypes.AUTH_THROW_ERROR, authThrowError);
  yield takeEvery(actionTypes.AUTH_DO_AUTO_LOG_OUT, doAutoLogout)
}