import * as constants from "../constants";
import EncryptUtils from "../../Util/EncryptUtils";
import CookieUtils from "../../Util/CookieUtils";

const getToken = () => {
  return CookieUtils.getCookie(constants.TOKEN);
};

const getAccessToken = () => {

  const token = getToken();
  if (token != null)
    return EncryptUtils.decrypt(token);
  return null;
};

const handleLogin = (token) => {
  const acess_token = EncryptUtils.encrypt(token.access_token)
  const options = { path: '/', secure: true, sameSite: true };
  CookieUtils.setCookie(constants.TOKEN, acess_token, options)
};

const handleLogout = () => {
  CookieUtils.removeCookie(constants.TOKEN);
  CookieUtils.removeCookie(constants.ROLES);
  CookieUtils.removeCookie(constants.ACCOUNT);
};

const setRoles = (roles) => {
  const encrypt_role = EncryptUtils.encrypt(roles.toString())
  const options = { path: '/', secure: true, sameSite: true };
  CookieUtils.setCookie(constants.ROLES, encrypt_role, options);
};

const getRoles = () => {
  return EncryptUtils.decrypt(CookieUtils.getCookie(constants.ROLES));
};

const setAccount = (account) => {
  const encrypt_account = EncryptUtils.encrypt(account)
  const options = { path: '/', secure: true, sameSite: true };
  CookieUtils.setCookie(constants.ACCOUNT, encrypt_account, options);
};

const getAccount = () => {
  return EncryptUtils.decrypt(CookieUtils.getCookie(constants.ACCOUNT));
};

const isAuthenticated = () => {
  return getToken() != null;
};

const isAdministor = () => {
  const roles = getRoles();
  return roles ? roles.includes(constants.ADMIN) : false;
};

const isUser = () => {
  const roles = getRoles();
  return roles ? roles.includes(constants.USER) : false;
};

export {
  handleLogin,
  handleLogout,
  getToken,
  getAccessToken,
  setRoles,
  getRoles,
  setAccount,
  getAccount,
  isAuthenticated,
  isAdministor,
  isUser,
};
