import * as actionTypes from "../constants";

export function getDevices() {
  return { type: actionTypes.GET_DEVICES };
}

export function getDevicesSuccess(data) {
  return { data, type: actionTypes.GET_DEVICES_SUCCESS };
}

export function updateDevice(data) {
  return { data, type: actionTypes.UPDATE_DEVICE };
}

export function removeDevice(data) {
  return { data, type: actionTypes.REMOVE_DEVICE };
}

export function deviceSuccess(data) {
  return { data, type: actionTypes.DEVICE_SUCCESS };
}




