import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const resetPassword = (dataReset) => {
  let httpConfig = {
    headers: {
      'Content-Type': 'application/json',
      'X-Authorization': dataReset.data.token,
    },
    url: urlConfig.urlResetPassword,
    method: 'POST',
    data: { "password": dataReset.data.password, "id": dataReset.data.id },
  }
  return httpService.request(httpConfig);
};

export const requestResetPassword = (dataRequest) => {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    },
    url: urlConfig.urlForgotPassword,
    method: 'POST',
    data: { "email": dataRequest.data.email },
  }

  return httpService.request(config);
};

export const isTokenValid = (dataRequest) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer ' + dataRequest.data.token,
    },
    method: "get",
    url: urlConfig.urlIsTokenValid + "?token=" + dataRequest.data.token + "&id=" + dataRequest.data.id,
  }
  return httpService.request(config);
};

export const changePassword = (dataRequest) => {
  return httpService.put(urlConfig.urlChangePassword, dataRequest.data);
}