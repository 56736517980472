import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  getConfigsWithKeys,
  updateConfigs,
} from "../../services/configuration/configurationService";

function* handleGetConfigsWithKeys(action) {
  try {
    const { keys } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Get configs ..." })
    );
    let response = yield call(getConfigsWithKeys, keys);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getConfigsWithKeysSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* handleUpdateConfigs(action) {
  try {
    const { array } = action;

    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Update configs ..." })
    );
    let response = yield call(updateConfigs, array);
    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.updateConfigsSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchConfiguration() {
  yield takeEvery(actionTypes.GET_CONFIGS_WITH_KEYS, handleGetConfigsWithKeys);
  yield takeEvery(actionTypes.UPDATE_CONFIGS, handleUpdateConfigs);
}
