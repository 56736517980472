import * as actionTypes from '../constants';

export function onCollapseMenu() {
  return { type: actionTypes.COLLAPSE_MENU };
}

export function onFullScreenExit() {
  return { type: actionTypes.FULL_SCREEN_EXIT };
}

export function onCollapseToggle(id, type) {
  return {type: actionTypes.COLLAPSE_TOGGLE, menu: {id: id, type: type}};
}

export function onFullScreen() {
  return {type: actionTypes.FULL_SCREEN};
}

export function onChangeLayout(layout) {
  return {type: actionTypes.CHANGE_LAYOUT, layout: layout};
}

export function onNavContentLeave() {
  return {type: actionTypes.NAV_CONTENT_LEAVE};
}

export function onNavCollapseLeave(id, type) {
  return {type: actionTypes.NAV_COLLAPSE_LEAVE, menu: {id: id, type: type}};
}

export function startLoading(data) {
  return { data, type: actionTypes.APP_START_LOADING };
}

export function cancelLoading() {
  return { type: actionTypes.APP_CANCEL_LOADING };
}
