import axios from 'axios'
import querystring from 'query-string'
import httpService from '../http'
import urlConfig from '../../configs/urlConfig'
import appConfig from '../../configs/appConfig'

export const getAccessToken = ({
	username,
	password
}) => {
	const parameters = {
		grant_type: "password",
		username: username,
		password: btoa(password)
	}
	return axios.request({
		url: urlConfig.oauthToken,
		method: "post",
		baseURL: urlConfig.host,
		headers: {
			"Content-type": "application/x-www-form-urlencoded; charset=utf-8",
		},
		auth: {
			username: appConfig.clientId,
			password: appConfig.clientSecret
		},
		data: querystring.stringify(parameters)
	});
};

export const getParamtersRefeshToken = ({
	refresh_token
}) => {
	const parameters = {
		grant_type: "refresh_token",
		refresh_token: refresh_token
	}
	return {
		url: urlConfig.oauthToken,
		method: "post",
		baseURL: urlConfig.host,
		headers: {
			"Content-type": "application/x-www-form-urlencoded; charset=utf-8",
		},
		auth: {
			username: appConfig.clientId,
			password: appConfig.clientSecret
		},
		data: querystring.stringify(parameters)
	};
};

export const getRoles = () => {
	return httpService.get(urlConfig.urlGetRoles);
};

export const logout = () => {
	return httpService.get(urlConfig.urlLogout);
};