import * as actionTypes from "../constants";

const initialState = {
  email: "",
  requested: "",
  passwordChanged: "",
  updatedUser: {},
  tokenChecked: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_RESET_PASSWORD:
      return {
        ...state,
        requested: ""
      };
    case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requested: action.data.meta.status
      };
    case actionTypes.RESET_PASSWORD:
      return {
        ...state,
        passwordChanged: ""
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordChanged: action.data.meta.status
      };
    case actionTypes.IS_TOKEN_VALID:
      return {
        ...state,
      };
    case actionTypes.CHECK_TOKEN_VALID_SUCCESS:
      return {
        ...state,
        tokenChecked: action.data
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatedUser: action.data,
      };
    default:
      return state;
  }
};

export default userReducer;
