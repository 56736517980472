import * as actionTypes from "../constants";

const initialState = {
  addedContact: {},
  updatedContact: {},
  removedContact: {},
  contacts: {},
  mailSendSuccess: ""
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.data,
      };

    case actionTypes.ADD_CONTACT_SUCCESS:
      return {
        ...state,
        addedContact: action.data,
      };

    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        updatedContact: action.data,
      };

    case actionTypes.REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        removedContact: action.data,
      };

    case actionTypes.SEND_MAIL_SUCCESS:
      return {
        ...state,
        mailSendSuccess: action.data.meta.status,
      };
    default:
      return state;
  }
};

export default contactReducer;
