import * as actionTypes from "../constants";

const initialState = {
  configurations: [],
  updatedConfigurations: [],
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONFIGS_WITH_KEYS_SUCCESS:
      return {
        ...state,
        configurations: action.data,
      };
    case actionTypes.UPDATE_CONFIGS_SUCCESS:
      return {
        ...state,
        updatedConfigurations: action.data,
      };
    default:
      return state;
  }
};

export default configurationReducer;
