import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  requestResetPassword,
  resetPassword,
  isTokenValid,
  changePassword,
} from "../../services/user/userService";

function* doRequestResetPassword(dataRequest) {
  try {
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Do request reset password ..." })
    );
    let response = yield call(requestResetPassword, dataRequest);
    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.doRequestResetPasswordSuccess(response.data));

  }
  catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doResetPassword(data) {
  try {
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Do reset password ..." })
    );
    let response = yield call(resetPassword, data);
    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.doResetPasswordSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doCheckToken(data) {
  try {
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Do check token ..." })
    );
    yield call(isTokenValid, data);
    // cancel loading ...
    yield put(actions.cancelLoading());

  } catch (error) {
    yield put(actions.handleError(error.response.data.meta));
  } finally {
    yield put(actions.doCheckTokenSuccess(true));
  }
}

function* doChangePassword(data) {
  try {
    // set loading ...
    yield put(
      actions.startLoading({ isLoading: true, message: "Do check token ..." })
    );
    let response = yield call(changePassword, data);
    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data
    yield put(actions.doChangePasswordSuccess(response));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchUser() {
  yield takeEvery(actionTypes.REQUEST_RESET_PASSWORD, doRequestResetPassword);
  yield takeEvery(actionTypes.RESET_PASSWORD, doResetPassword);
  yield takeEvery(actionTypes.IS_TOKEN_VALID, doCheckToken);
  yield takeEvery(actionTypes.CHANGE_PASSWORD, doChangePassword);
}
