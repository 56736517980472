import {
  takeEvery,
  call,
  put
} from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  getDevices,
  updateDevice,
  removeDevice,
} from "../../services/device/deviceService";

function* doGetDevices() {
  try {
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Get devices ..."
      })
    );
    let response = yield call(getDevices);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getDevicesSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doUpdateDevice(action) {
  try {
    const {
      data
    } = action;

    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Update device ..."
      })
    );
    let response = yield call(updateDevice, data);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.deviceSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doRemoveDevice(action) {
  try {
    const {
      data
    } = action;

    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Remove device ..."
      })
    );
    let response = yield call(removeDevice, data.id);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.deviceSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchDevice() {
  yield takeEvery(actionTypes.GET_DEVICES, doGetDevices);
  yield takeEvery(actionTypes.UPDATE_DEVICE, doUpdateDevice);
  yield takeEvery(actionTypes.REMOVE_DEVICE, doRemoveDevice);
}