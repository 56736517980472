export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const APP_START_LOADING = "APP_START_LOADING";
export const APP_CANCEL_LOADING = "APP_CANCEL_LOADING";
// eslint-disable-next-line no-script-url
export const BLANK_LINK = "javascript:void(0)";

export const TestCaseStatus = {
  OK: "OK",
  NG: "NG",
  NG_ISP: "NG_ISP",
};
