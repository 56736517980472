import * as actionTypes from "../constants";

export function doRequestResetPassword(data) {
  return { data, type: actionTypes.REQUEST_RESET_PASSWORD };
}

export function doRequestResetPasswordSuccess(data) {
  return { data, type: actionTypes.REQUEST_RESET_PASSWORD_SUCCESS };
}

export function doResetPassword(data) {
  return { data, type: actionTypes.RESET_PASSWORD };
}

export function doResetPasswordSuccess(data) {
  return { data, type: actionTypes.RESET_PASSWORD_SUCCESS };
}

export function doCheckToken(data) {
  return { data, type: actionTypes.IS_TOKEN_VALID };
}

export function doCheckTokenSuccess(data) {
  return { data, type: actionTypes.CHECK_TOKEN_VALID_SUCCESS };
}

export function doChangePassword(data) {
  return { data, type: actionTypes.CHANGE_PASSWORD };
}

export function doChangePasswordSuccess(data) {
  return { data, type: actionTypes.CHANGE_PASSWORD_SUCCESS };
}