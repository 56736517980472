const host = process.env.REACT_APP_CONFIG_API_HOST;

const urlConfig = {
  oauthToken: `${host}/oauth/token`,
  urlGetRoles: `${host}/api/v1/users/me/roles`,
  urlLogout: `${host}/api/v1/users/me/logout`,
  urlDevices: `${host}/api/v1/devices`,
  urlContacts: `${host}/api/v1/contacts`,
  urlDashboardSearch: `${host}/api/v1/dashboard/search`,
  urlDashboardTestStatus: `${host}/api/v1/dashboard/test/status`,
  urlConfigurations: `${host}/api/v1/configs`,
  urlReportListSearch: `${host}/api/v1/report/list/search`,
  urlReportDetail: `${host}/api/v1/report/detail`,
  urlGetImage: `${host}/api/v1/file`,
  urlDeleteReportManually: `${host}/api/v1/report/delete/manually`,
  urlForgotPassword: `${host}/api/v1/users/forgot-password`,
  urlResetPassword: `${host}/api/v1/users/reset-password`,
  urlRoleList: `${host}/api/v1/users/roles`,
  urlIsTokenValid: `${host}/api/v1/users/token`,
  urlSendMailActiveAccount: `${host}/api/v1/users/resend-active-mail`,
  urlChangePassword: `${host}/api/v1/users/me/change-password`,
  urlLoginHistory: `${host}/api/v1/user-login-history`,
  urlExportLoginHistory: `${host}/api/v1/export-user-login-history`,
};

export default urlConfig;
