import * as actionTypes from "../constants";

export function getTestCaseStatus() {
  return { type: actionTypes.DASHBOARD_GET_TEST_CASE_STATUS };
}

export function getTestCaseStatusSuccess(data) {
  return { data, type: actionTypes.DASHBOARD_GET_TEST_CASE_STATUS_SUCCESS };
}

export function searchReport(data) {
  return { data, type: actionTypes.DASHBOARD_SEARCH_REPORT };
}

export function searchReportSuccess(data) {
  return { data, type: actionTypes.DASHBOARD_SEARCH_REPORT_SUCCESS };
}




