import * as actionTypes from '../constants';

export function doLogin(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN
  };
}

export function doLoginSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN_SUCCESS
  };
}

export function doLoginFailure(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_ACCESS_TOKEN_FAILURE
  };
}

export function doGetRoles() {
  return {
    type: actionTypes.AUTH_DO_GET_ROLES
  };
}

export function doGetRolesSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_GET_ROLES_SUCCESS
  };
}

export function doLogout() {
  return {
    type: actionTypes.AUTH_DO_LOG_OUT
  };
}

export function doAutoLogout() {
  return {
    type: actionTypes.AUTH_DO_AUTO_LOG_OUT
  };
}

export function doLogoutSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_DO_LOG_OUT_SUCCESS
  };
}

export const authThrowError = (data) => ({
  type: actionTypes.AUTH_THROW_ERROR,
  data,
});