import * as actionTypes from "../constants";

const initialState = {
  status: {},
  dataReportList: {},
  dataReportListMeta: {},
  deleteReportManually: {},
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_LIST_SEARCH_REPORT_SUCCESS:
      return {
        ...state,
        dataReportList: action.data.data,
        dataReportListMeta: action.data.meta,
      };
    case actionTypes.GET_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        dataReportDetail: action.data,
      };
    case actionTypes.DELETE_REPORT_MANUALLY_SUCCESS:
      return {
        ...state,
        deleteReportManually: new Date(),
      };
    default:
      return state;
  }
};

export default reportReducer;
