import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './index.scss';

export function comfirmPopup(props) {
	return (
		confirmAlert({
			closeOnEscape: false,
			closeOnClickOutside: false,
			willUnmount: () => { },
			afterClose: () => { },
			onClickOutside: () => { },
			onKeypressEscape: () => { },
			customUI: ({ onClose }) => {
				return (
					<div className='custom-ui'>
						<h3>{props.message}</h3>
						{
							props.buttonNegative &&
							<button
								className='btn btn-rounded btn-secondary'
								onClick={() => {
									props.onClickButonNegative()
									onClose()
								}}
							>
								{props.buttonNegative}
							</button>
						}
						{
							props.buttonPositve &&
							<button
								className='btn btn-rounded btn-primary'
								onClick={() => {
									props.onClickButonPositive()
									onClose()
								}}
							>
								{props.buttonPositve}
							</button>
						}
					</div>
				)
			}
		})
	)
}
