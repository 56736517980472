import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import {
  searchReportList,
  getReportDetail,
  deleteReportManually,
} from "../../services/reportList/reportListService";

function* doSearchReport(data) {
  try {
    let dataSearch = data.data;
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Report List Search Report ...",
      })
    );
    let response = yield call(searchReportList, dataSearch);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.searchReportListSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doGetReportDetail(data) {
  try {
    let id = data.data;
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Report List Get Report Detail...",
      })
    );
    let response = yield call(getReportDetail, id);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getReportDetailSuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

function* doDeleteReportManually(data) {
  try {
    let dataDelete = data.data;
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Delete Report Manually...",
      })
    );
    let response = yield call(deleteReportManually, dataDelete);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.deleteReportManuallySuccess(response.data.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchDevice() {
  yield takeEvery(actionTypes.REPORT_LIST_SEARCH_REPORT, doSearchReport);
  yield takeEvery(actionTypes.GET_REPORT_DETAIL, doGetReportDetail);
  yield takeEvery(actionTypes.DELETE_REPORT_MANUALLY, doDeleteReportManually);
}
