export const GET_CONTACTS_WITH_KEYWORD = "GET_CONTACTS_WITH_KEYWORD";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";

export const ADD_CONTACT = "ADD_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";

export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";

export const SEND_MAIL_ACTIVE_ACCOUNT = "SEND_MAIL_ACTIVE_ACCOUNT";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";