import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const checkTestCaseStatus = () => {
  return httpService.get(urlConfig.urlDashboardTestStatus);
};

export const searchReportDashboard = (dataSearch) => {
  return httpService.post(urlConfig.urlDashboardSearch, dataSearch);
};
