import httpService from "../http";
import urlConfig from "../../configs/urlConfig";

export const searchReportList = (dataSearch) => {
  return httpService.post(urlConfig.urlReportListSearch, dataSearch);
};

export const getReportDetail = (id) => {
  return httpService.get(`${urlConfig.urlReportDetail}/${id}`);
};

export const deleteReportManually = (data) => {
  return httpService.post(urlConfig.urlDeleteReportManually, data);
};
