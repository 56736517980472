import * as actionTypes from "../constants";

const initialState = {
  status: {},
  dataChart: {}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_GET_TEST_CASE_STATUS_SUCCESS:
      return {
        ...state,
        status: action.data,
      };
    case actionTypes.DASHBOARD_SEARCH_REPORT_SUCCESS:
      return {
        ...state,
        dataChart: action.data,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
