import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions";
import * as actionTypes from "../constants";
import { getRoles } from "../../services/role/roleService";

function* doGetRoles() {
  try {
    // set loading ...
    yield put(
      actions.startLoading({
        isLoading: true,
        message: "Get roles ...",
      })
    );
    let response = yield call(getRoles);

    // cancel loading ...
    yield put(actions.cancelLoading());

    // set data ...
    yield put(actions.getRolesSuccess(response.data));
  } catch (error) {
    yield put(actions.throwError(error));
  }
}

export default function* watchRole() {
  yield takeEvery(actionTypes.GET_ROLES, doGetRoles);
}
