import axios from 'axios'
import {
  getAccessToken,
} from '../../stores/storages'

export default class HttpService {
  constructor(defaultConfig) {
    this.defaultConfig = defaultConfig;
  }

  request(config) {
    return axios.request(config);
  }

  get(url, config) {
    this.defaultConfig.headers['Authorization'] = 'Bearer ' + getAccessToken()
    config = config || this.defaultConfig
    config.url = url
    config.method = 'GET'
    return this.request(config)
  }

  post(url, data, config) {
    this.defaultConfig.headers['Authorization'] = 'Bearer ' + getAccessToken()
    config = config || this.defaultConfig
    config.url = url
    config.method = 'POST'
    config.data = data
    return this.request(config)
  }

  put(url, data, config) {
    this.defaultConfig.headers['Authorization'] = 'Bearer ' + getAccessToken()
    config = config || this.defaultConfig
    config.url = url
    config.method = 'PUT'
    config.data = data
    return this.request(config)
  }

  delete(url, data, config) {
    this.defaultConfig.headers['Authorization'] = 'Bearer ' + getAccessToken()
    config = config || this.defaultConfig
    config.url = url
    config.method = 'DELETE'
    config.data = data
    return this.request(config)
  }
}